<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">PREEvision数据交换</h1>
        <p data-caption-delay="100" class="wow fadeInRight">实现PREEvision与其他工具、系统数据交换与互联互通，实现数据自动流转与监控</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <section class="solution-intros preevision2x-solution-intro wow fadeInLeft">
      <div class="container">
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <div class="intro-text">
                <p>在PREEvision的使用过程中，经常需要与第三方应用系统进行数据交换，传统依靠手动文件导入、导出的方式效率低、操作繁琐。</p>
              </div>
              <div class="intro-text">
                <p>PREEvision数据交换平台可系统化的实现PREEvision与其他工具、系统数据交换与互联互通，打通不同系统间的信息孤岛，实现数据自动流转与监控，提升工作效率。</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
            <img src="../../../assets/images/solution-preevision2x-intro.png" alt="" style="display: block;width: 90%;margin: 0 auto;">
          </el-col>
        </el-row>
      </div>
      <div class="left-bg">
        <img src="../../../assets/images/index-about-bg-01.png" alt="">
      </div>
      <div class="right-bg">
        <img src="../../../assets/images/index-about-bg-02.png" alt="">
      </div>
    </section>
    <section class="vehicle-solution-sections solution-functions preevision2x-solution-function wow fadeInRight">
      <div class="container">
        <div class="normal-title">功能特点</div>
        <div
            class="all-function"
            @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
            @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
        >
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in functionList" :key="index">
              <div class="card-item">
                <el-card class="solution-function-card" shadow="hover">
                  <div class="card-icon">
                    <img :src="item.icon" :style="'width: ' + item.width +';padding-top:' + item.padding">
                  </div>
                  <div class="card-title">
                    <h1>{{ item.title }}</h1>
                  </div>
                  <div class="card-text">
                    <p>{{ item.content }}</p>
                  </div>
                </el-card>
              </div>
            </swiper-slide>
            <!-- 根据情况引入控件 -->
            <!--            <div class="swiper-pagination" slot="pagination"></div>-->
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <section class="vehicle-solution-sections preevision2x-strength wow fadeInLeft">
      <div class="container">
        <div class="normal-title">优势亮点</div>
        <div class="solution-strength">
          <div class="strength-content">
            <el-row>
              <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
                <div class="strength-text">
                  <ul class="priority-list">
                    <li>建立统一的业务标准，使数据按一定业务规则成为可复用的信息资源服务</li>
                    <li>内置多种数据同步、事务控制策略，保证数据一致性</li>
                    <li>可视化的统一管理，并对服务运行、交互状态进行实时监控</li>
                    <li>第三方平台，独立部署，通过集群化、负载均衡方式扩展性能并保证稳定性</li>
                    <li>具备丰富的大数据处理、PREEvision二次开发经验，进行算法优化，提升数据交换效率</li>
                  </ul>
                </div>
              </el-col>
              <el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10">
                <div class="strength-picture">
                  <img src="../../../assets/images/solution-preevision2x-strength-pic.png" alt="">
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </section>
    <section class="solution-application wow fadeInRight">
      <div class="container">
        <div class="normal-title">应用领域</div>
        <div class="all-application">
          <div class="application-content">
            <div class="application-icon">
              <img src="../../../assets/images/application-picture.svg" alt="">
            </div>
            <div class="application-text">
              <p>E/E架构设计</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="dyna-product-system-picture pv2x-system-pic wow fadeInLeft">
      <div class="container">
        <div class="normal-title">系统图片</div>
        <div class="all-system-picture">
          <div class="system-picture">
            <img src="../../../assets/images/solution-preevision2x-system-pic.png" alt="">
          </div>
        </div>
      </div>
    </section>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from '@/components/MainNav'
import mainFooter from '@/components/MainFooter'
import bzInfo from '@/components/BzInfo'
export default {
  name: "preevision2x",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data(){
    return {
      activeIndex: '2-1',
      functionList:[
        {
          icon: require('../../../assets/images/solutions/pv-01.svg'),
          width: "27%",
          padding: "0px",
          title: "数据访问",
          content: "利用PREEvision提供的Server API模型数据访问接口，使外部Web应用系统和第三方应用程序能够通过HTTP方式读取/写入PREEvision各类数据模型"
        },
        {
          icon: require('../../../assets/images/solutions/pv-02.svg'),
          width: "30%",
          padding: "-8px",
          title: "数据处理",
          content: "对PREEvision 提供的Server API接口进行二次封装并优化，简化处理流程、提升数据交换效率，使数据按一定业务规则成为可复用的信息资源"
        },
        {
          icon: require('../../../assets/images/solutions/pv-04.svg'),
          width: "23%",
          padding: "2px",
          title: "文件传输",
          content: "支持将文件（Word、Excel、ODT、PDF等）解析为结构化数据并进行数据交换；支持将业务文件上传到平台中，同步到相关的应用系统"
        },
        {
          icon: require('../../../assets/images/solutions/pv-05.svg'),
          width: "27%",
          padding: "0px",
          title: "配置管理",
          content: "提供系统的用户管理、部门管理、岗位管理、角色管理、权限管理、菜单管理、菜单授权、字典配置等配置管理"
        },
        {
          icon: require('../../../assets/images/solutions/pv-06.svg'),
          width: "27%",
          padding: "0px",
          title: "服务监控",
          content: "平台对每一笔数据交换内容进行记录，包括数据交换的时间、报文、状态等信息，可通过服务监控追溯服务调用的整个过程"
        },
        {
          icon: require('../../../assets/images/solutions/pv-07.svg'),
          width: "27%",
          padding: "2px",
          title: "统计分析",
          content: "以时间维度，对平台内所有服务的调用信息、异常信息、PREEvision构件信息等进行统计，协助管理者更好的了解服务运行的整体状态"
        }, {
          icon: require('../../../assets/images/solutions/pv-03.svg'),
          width: "25%",
          padding: "0px",
          title: "定制开发",
          content: "支持按照业务需求，在数据交换平台进行PREEvision模型数据的展示、配置、统计、指标评估等"
        }
      ],
      swiperOption: {
        spaceBetween: 10, // 图片之间的间距
        centeredSlides: false, // 居中还是从图1开始
        slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
        breakpointsInverse: true,
        breakpoints: {
          //当宽度大于等于1100
          1100: {
            slidesPerView: 4,
            spaceBetween: 10
          }
        },
        notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
        // loop: true, // 循环吗
        initialSlide: 0, // 从第几个开始
        autoplay: {
          delay: 5000, // 等5秒下一个
          disableOnInteraction: false // 中间滑动一下，取消自动吗？
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }, // 下按钮
        speed: 800, // 滑动时候动画的速度
        paginationClickable: true, // 下面按钮让点吗
        navigation: {
          prevEl: '.swiper-button-prev', // 左侧按钮
          nextEl: '.swiper-button-next' // 右侧按钮
        },
        // effect: 'fade', // 渐入效果
        watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
        watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
        // autoHeight: true  // 图片高度自适应
      },
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
    }
  },
  mounted() {
    new this.$wow.WOW().init();
    document.title = "PREEvision数据交换平台 - 解决方案 - 东信创智";
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
  }
}
</script>
